import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  direction?: 'L' | 'R'
  onClick?: (e: any) => void
}

export const Arrow = memo(function Arrow({ direction = 'L', onClick }: Props) {
  return (
    <Container dial={5} onClick={onClick}>
      {direction === 'L' ? <ChevronLeft /> : <ChevronRight />}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 4.375rem;
  height: 4.375rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0.625rem;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.primaryLight};
  }
  &:first-of-type {
    margin-left: 0;
  }

  svg {
    width: auto;
    height: 1.5rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 2;
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 1023px) {
    width: 3.125rem;
    height: 3.125rem;

    svg {
      height: 1.125rem;
    }
  }
`
