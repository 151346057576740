import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  image?: ImageProps
  services: ServiceProps[]
  title?: string
}

export const RoomsServices = memo(function RoomsServices({
  image,
  services,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container row tag="section" stretch wrap>
      <LeftSide>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </LeftSide>

      <RightSide dial={5}>
        {title ? <Title>{title}</Title> : null}

        <Services row wrap>
          {services.map((item, index) => (
            <Service key={index} {...item} />
          ))}
        </Services>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
`

const LeftSide = styled.div`
  width: 50%;
  min-height: 77.8vh;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    min-height: auto;
    padding-bottom: 72%;
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;
  padding: 5.625rem 1.3125rem;

  @media (max-width: 1023px) {
    width: 100%;
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.5rem;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
`

const Services = styled(FlexBox)``
