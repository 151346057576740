import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { Resize, Users } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Gallery } from './Gallery'

export interface Props {
  beURL?: string
  description?: string
  highlight?: string
  images?: ImageProps[]
  languageCode: string
  pax?: string
  size?: string
  subtitle?: string
  title?: string
}

export const Room = memo(function Room({
  beURL,
  description,
  highlight,
  images,
  languageCode,
  pax,
  size,
  subtitle,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {images ? <Gallery highlight={highlight} images={images} /> : null}

        <Wrapper row space="between">
          <LeftSide>
            {title ? (
              <FadeInUp>
                <Title>{title}</Title>
              </FadeInUp>
            ) : null}

            {subtitle ? (
              <FadeInUp>
                <Subtitle dial={4} row>
                  <Line />
                  {subtitle}
                </Subtitle>
              </FadeInUp>
            ) : null}

            <Media lessThan="ipadHorizontal">
              <RightSide>
                {pax ? (
                  <FadeInUp>
                    <Pax dial={4} row>
                      <Users />
                      <Label>{pax}</Label>
                    </Pax>
                  </FadeInUp>
                ) : null}

                {size ? (
                  <FadeInUp>
                    <Size dial={4} row>
                      <Resize />
                      <Label>{size}</Label>
                    </Size>
                  </FadeInUp>
                ) : null}
              </RightSide>
            </Media>

            {description ? (
              <FadeInUp>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </FadeInUp>
            ) : null}

            {beURL ? (
              <FadeInUp>
                <CTA
                  label={useVocabularyData('book-this-room', languageCode)}
                  target="_blank"
                  URL={beURL}
                />
              </FadeInUp>
            ) : null}
          </LeftSide>

          <Media greaterThanOrEqual="ipadHorizontal">
            <RightSide>
              {pax ? (
                <FadeInUp>
                  <Pax dial={4} row>
                    <Users />
                    <Label>{pax}</Label>
                  </Pax>
                </FadeInUp>
              ) : null}

              {size ? (
                <FadeInUp>
                  <Size dial={4} row>
                    <Resize />
                    <Label>{size}</Label>
                  </Size>
                </FadeInUp>
              ) : null}
            </RightSide>
          </Media>
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.div`
  margin-top: 9rem;
  padding: 0 10vw;

  @media (max-width: 1023px) {
    margin-top: 5.3125rem;
    padding: 0 1.3125rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin-top: 3.75rem;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    margin-top: 2.5rem;
  }
`

const LeftSide = styled.div`
  width: 66.666%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.125rem;

  @media (max-width: 1023px) {
    font-size: 2.1875rem;
    line-height: 2.8125rem;
  }
`

const Subtitle = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  letter-spacing: 0.1125rem;
  line-height: 1.3125rem;
  margin-top: 1.5625rem;
  text-transform: uppercase;

  > div {
    margin: 0 1.5rem 0 0;
  }

  @media (max-width: 1023px) {
    font-size: 0.9375rem;
    letter-spacing: 0.0938rem;
    line-height: 1.1875rem;
    margin-top: 0.375rem;

    > div {
      display: none;
    }
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2.125rem;
  margin-top: 1.875rem;

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 2rem;
    margin-top: 1.875rem;
  }
`

const CTA = styled(Button)`
  margin-top: 3.125rem;

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
  }
`

const RightSide = styled.div`
  max-width: 15.625rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.1875rem;
  padding-right: 5.903vw;

  svg {
    width: 1.875rem;
    height: auto;
    max-height: 1.875rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-right: 0.75rem;
  }

  @media (max-width: 1023px) {
    max-width: none;
    margin-top: 1.5rem;
    padding-right: 0;

    > div {
      &:nth-of-type(2) {
        margin-top: 0.625rem;
      }
    }

    svg {
      width: 1.5rem;
      max-height: 1.5rem;
    }
  }
`

const Pax = styled(FlexBox)`
  margin-bottom: 1.875rem;

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
`

const Size = styled(FlexBox)``

const Label = styled.span`
  width: calc(100% - 2.625rem);
`
