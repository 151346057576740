import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { Heart } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  highlight?: string
  images: ImageProps[]
}

export const Gallery = memo(function Gallery({ highlight, images }: Props) {
  if (images.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (instanceRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    defaultAnimation: {
      duration: 2000,
    },
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {highlight ? (
          <Highlight dial={5} row>
            <Heart />
            <Label>{highlight}</Label>
          </Highlight>
        ) : null}

        <Slider ref={sliderRef}>
          {uniq(images).map((item, index) => (
            <Slide className="keen-slider__slide" key={index}>
              <Inner style={positionStyle(index)}>
                {loaded[index] ? (
                  <Image
                    {...item}
                    alt={loaded[index] ? item.alt : undefined}
                    media="(min-width: 2800px)"
                  />
                ) : (
                  <Spinner />
                )}
              </Inner>
            </Slide>
          ))}
        </Slider>

        {images.length > 1 ? (
          <>
            <Media greaterThanOrEqual="ipadHorizontal">
              <Arrows row>
                <Arrow
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.prev()
                  }
                />
                <Arrow
                  direction="R"
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.next()
                  }
                />
              </Arrows>
            </Media>

            <Media lessThan="ipadHorizontal">
              <Dots dial={4} row>
                {images.map((_item, index) => (
                  <Dot
                    key={index}
                    className={currentSlide === index ? 'active' : undefined}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(index)
                    }}
                  />
                ))}
              </Dots>
            </Media>
          </>
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 16.7vh;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.5)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

const Highlight = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.1875rem;
  position: absolute;
  bottom: 4.625rem;
  left: 5.139vw;
  z-index: 2;

  svg {
    width: auto;
    height: 1.875rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: 0.75rem;
  }

  @media (max-width: 1023px) {
    bottom: 1.875rem;
    right: 1.3125rem;
    left: 1.3125rem;

    svg {
      flex: 0 0 auto;
      height: 1.5rem;
    }
  }
`

const Label = styled.div`
  width: calc(100% - 1.875rem);

  @media (max-width: 1023px) {
    width: auto;
  }
`

const Slider = styled.div`
  display: flex;
  height: 72.2vh;
  overflow: hidden;
  position: relative;

  @media (max-width: 1023px) {
    height: 33.5vh;
  }
`

const Slide = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: 3.75rem;
  right: 5.139vw;
  z-index: 2;
`

const Dots = styled(FlexBox)`
  height: 0.75rem;
  position: absolute;
  top: 1.875rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  cursor: pointer;
  margin: 0 0.75rem;
  transition: 0.3s ease-in-out;
  &.active {
    width: 0.75rem;
    height: 0.75rem;
    background: transparent;
    border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
    pointer-events: none;
  }
`
